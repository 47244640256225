* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }
  
  body {
    background: #eeeeee;
    color: #030303;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  header {
    text-align: center;
    padding: 3rem 0;
    background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%);
    color: white;
  }
  
  header > h1 {
    font-weight: normal;
    font-style: italic;
  }
  
  main {
    padding: 1rem 0;
  }
  
  .container {
    max-width: 960px;
    width: 100%;
    margin: 0 auto;
  }
  
  .movie {
    width: 100%;
  }
  
  .ads {
    padding: 4px;
    padding-right: 8px;
    background: white;
    width: 80%;
  
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .ads__link {
    display: flex;
    color: inherit;
    text-decoration: inherit;
  }
  
  .ads__img {
    width: 80px;
    min-width: 80px;
    height: 80px;
    margin-right: 16px;
  }
  
  .ads__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .ads__title {
    margin: 0;
  }
  .ads__body {
    margin: 0;
  }